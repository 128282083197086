.stats {
    margin-top: 50px;
}

.stat-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.stat-number {
    font-weight: 400;
    font-size: 58px;
    text-align: center;
    flex: 1;
}

.stat-label {
    flex: 2;
    font-size: 24px;
    text-align: center;
}

.playbutton-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}