.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ebefea;
}

.map-container {
  display: flex;
  width: 100%;
}

.map-container .form-container, .map-container .details-container {
  min-width: 350px;
  padding: 30px;
  max-width: 20%;
  color: black;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .map-container {
    flex-direction: column;
  }
  
  .map-container .form-container, .map-container .details-container {
    width: 100%;
    max-width: 100%;
  }
}

.details-container {
  overflow-y: scroll;
  max-height: 700px;
  width: 400px;
}

.details-container ol {
  text-align: left;
}

.details-container ol li {
  margin-bottom: 12px;
}