.marker {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.dot {
    border: 3px solid white;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    box-shadow: -1px 3px 5px #333333;
}

.claim {
    background-color: #ff0000;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.no-claim {
    background-color: black;
}

.clickable-area {
    height: 50px;
    width: 50px;
    cursor: pointer;
}
