article {
  margin-top: 2em;
}

article p {
  text-align: left;
}

article h2 {
  text-align: left; 
}

article p:first-child {
  font-size: larger;
}