.map {
    border-radius: 17px;
    margin-top: 20px;
}

.map  > div > div {
    border-radius: 15px;
}

.button-container { 
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1200;
}