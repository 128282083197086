.header {
    box-shadow: 0 1px 1px #999;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: #d1d5d0;
}

.header .main-header {
    font-family: 'Anton', sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 5rem;
    font-weight: 700;
    letter-spacing: 4px;
    line-height: 1em;
}

.header .sub-header {
    margin-top: 0;
    font-weight: normal;
    font-size: 1.5rem;
    margin-bottom: 0;
}

.header p {
    margin: 0 0 5px;
}

.info {
    text-align: right;
    padding: 20px;
}

.title {
    padding: 20px;
}

@media only screen and (max-width: 900px) {
    .header {
      flex-direction: column;
    }
    .info {
        text-align: center;
    }
}