footer {
  padding: 2rem 0;
  width: 100%;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
}