.instructions {
    position: relative;
}

.instructions-popup {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 1px 1px #999;
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    padding-right: 40px;
}

.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    margin: 0 auto;
}

.marker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 20px;
}

.key-label {
    margin-left: 8px;
}

.reservation {
    width: 25px;
    height: 25px;
    border: 3px solid black;
    border-radius: 30%;
    background-color: #f3d5a0;
    box-shadow: -1px 3px 5px #333333;
}